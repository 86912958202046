import { useState } from "react";
import "../Map/map.css";
import "./countryselector.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default function CountrySelector({ countrysArray }) {
    const [isActive, setIsActive] = useState(false);
    const [countryInfo, setCountryInfo] = useState(countrysArray[0]);
    const [searchTerm, setSearchTerm] = useState("");

    function handleFocus() {
        setIsActive(true);
    }

    function handleBlur() {
        setTimeout(()=> {
            setIsActive(false);
        },200)
    }

    function handleChoose(e) {
        changeCountryInfo(e.target.textContent);
        setIsActive(false);
    }

    function changeCountryInfo(name) {
        let setObj = {};
        const foundObject = countrysArray.find((obj) => obj.name === name);
        if (foundObject) {
            setObj = {
                name: foundObject.name,
                isoCode: foundObject.isoCode,
                price: foundObject.price,
                conv: foundObject.conv,
            };
            setCountryInfo(setObj);
        }
    }

    const filteredCountries = countrysArray.filter((country) =>
        country.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="country_select_info">
            <div className="dropdown">
                <div className="dropdown-btn">
                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                    <input
                        type="text"
                        placeholder="Search countries"
                        className="search_country_input"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onFocus={handleFocus}
                        onBlur={(e) => handleBlur(e)}
                    />
                </div>
                <div
                    className="dropdown-content"
                    style={{ display: isActive ? "block" : "none" }}
                >
                    {filteredCountries.map(function (e, i) {
                        return (
                            <div
                                onClick={(e) => handleChoose(e)}
                                className="item"
                                key={i}
                                data-name={e.name}
                            >
                                {e.name}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="country_select_info_box">
                <div>
                    <div className="country_title">
                        <div
                            className={`iti__flag iti__${countryInfo.isoCode.toLowerCase()}`}
                        ></div>
                        <p className="country_title_info">{countryInfo.name}</p>
                    </div>
                    <p className="country_title_info">{`$${countryInfo.price}`}</p>
                    <p className="country_title_info">{`CR ${countryInfo.conv}`}</p>
                </div>
            </div>
        </div>
    );
}
