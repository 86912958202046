import "./hedader_bottom.css";
import LanguageContext from "../../LanguageContext/LanguageContext";
import {useContext, useEffect, useState} from "react";
export default function HedaderBottom(){
    const { translations } = useContext(LanguageContext);

    let imgArr = {
        imgCircle : require('../../images/circle_text.png'),
        imgGradient : require('../../images/gradient.png'),
        imgScode : require('../../images/s_code.png'),
        imgLogoMask : require('../../images/logo_icon_transparent_b.png'),

    }
    let imgChArr = {
        imgLogoMask1 : require('../../images/material_edited2-removebg-preview.png'),
        imgLogoMask2 : require('../../images/material_edited3-removebg-preview.png'),
        imgLogoMask3 : require('../../images/material_edited4-removebg-preview.png'),
    }

    const [imgLogoMaskCh, setImgLogoMaskCh] = useState(imgArr.imgLogoMask);
    useEffect(()=>{
        let currentIndex = 0;
        let imageKeys = Object.keys(imgChArr);
        function changeImage() {
            // Устанавливаем новый путь к изображению
            setImgLogoMaskCh(imgChArr[imageKeys[currentIndex]]);
            console.log(imgChArr[imageKeys[currentIndex]])
            // Увеличиваем индекс или сбрасываем на 0, если достигнут конец массива
            currentIndex = (currentIndex + 1) % imageKeys.length;
        }
        // Запускаем функцию каждую секунду
        setInterval(changeImage, 1000);
    },[])

    return(
        <div className='hedader_bottom'>
            <div className='hedader_bottom_info'>
                <div className='hedader_bottom_info_box'>
                    <p className='hedader_bottom_info_box_text'>
                        {translations.mainPageSubtitle}
                    </p>
                </div>
                <div className='hedader_bottom_info_circle_text'>
                    <img src={imgArr.imgCircle} alt="#"/>
                </div>
                <div className='hedader_bottom_info_box year'>
                    <span>
                        since 2022
                    </span>
                </div>
            </div>
            <div className='hedader_bottom_shin'>
                <img src={imgLogoMaskCh} alt="#"/>
            </div>
            <div className='hedader_bottom_scode'>
                <img src={imgArr.imgScode} alt="#"/>
            </div>

        </div>
    )
}