import { ComposableMap, Geographies, Geography } from "react-simple-maps"
import './map.css';
import geoUrl from './raw.githubusercontent.com_deldersveld_topojson_master_world-countries.json';
import {useState} from "react";




export default function MapChart({countrysArray}) {

    const countryArray = countrysArray;

    const [countryInfo, setCountryInfo] = useState(countryArray[0]);
    const [countryWorkWith,setCountryWorkWith] = useState(true)
    const handleMouseEnter = (e) => {
        console.log('handleMouseEnter',e.properties)
        let countryObj = {name: e.properties.name, isoCountry: e.properties["Alpha-2"]};
        countryDataSetter(e.properties["Alpha-2"], e.properties.name)
    }

    const countryDataSetter = (iso, name) =>{
        let valueToFind = iso;
        let setObj = {};

        const foundObject = countryArray.find(obj => obj.isoCode === valueToFind);
        if (foundObject) {
            console.log(`Значення "${valueToFind}" існує в масиві об'єктів.`);
            console.log('foundObject',foundObject);
            setObj = {name : foundObject.name, isoCode: foundObject.isoCode, price: foundObject.price, conv: foundObject.conv};
            setCountryWorkWith(true);
            console.log('foundObject',foundObject.name);
        } else {
            console.log(`Значення "${valueToFind}" не знайдено в масиві об'єктів.`);
            setObj = {name : name, isoCode: iso ? iso : '', price: '-', conv: '-'};
            setCountryWorkWith(false);
            console.log('NOT foundObject',setObj.name);
        }
        setCountryInfo(setObj);
    }

    return (
       <div className='map_box'>
           <div className='main_map'>
               <ComposableMap>
                   <Geographies geography={geoUrl}>
                       {({ geographies }) =>
                           geographies.map((geo) => (
                               <Geography key={geo.rsmKey}  onMouseEnter={(e)=> handleMouseEnter(geo)} geography={geo} />
                           ))
                       }
                   </Geographies>
               </ComposableMap>
           </div>
           <div className="country_name_box">
               <div className='country_name'>
                   <div className='country_title'>
                       <div className={`iti__flag iti__${countryInfo.isoCode.toLowerCase()}`}></div>
                       <p>{countryInfo.name}</p>
                   </div>
                   {
                       countryWorkWith ?
                           <>
                               <p>{`$${countryInfo.price}`}</p>
                               <p>{`CR ${countryInfo.conv}`}</p>
                           </>
                       :
                            <p className='country_err_text'>
                       At the moment we don't work with this geo, but everything may change soon</p>
                   }

               </div>
           </div>
       </div>
    )
}