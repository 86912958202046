import MapChart from "./SvgMap";
import {useEffect, useState} from "react";
import CountrySelector from "../CountrySelector/CountrySelector";

export default function Map(){

    const [isMob , setIsMob] = useState(false)
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 480px)'); // Установите нужный вам медиа-запрос
        const handleResize = (event) => {
            setIsMob(event.matches);
        };
        mediaQuery.addListener(handleResize);
        setIsMob(mediaQuery.matches);
        return () => {
            mediaQuery.removeListener(handleResize);
        };
    }, [isMob]);

    const countryArray = [
        {name : 'Australia', isoCode: 'AU', price: '1100', conv: '10%'},
        {name : 'Austria', isoCode: 'AT', price: '1000', conv: '10%'},
        {name : 'Azerbaijan', isoCode: 'AZ', price: '500', conv: '4%'},
        {name : 'Argentina', isoCode: 'AR', price: '450', conv: '4%'},
        {name : 'Armenia', isoCode: 'AM', price: '500', conv: '5%'},
        {name : 'Bahrain', isoCode: 'BH', price: '950', conv: '8%'},
        {name : 'Belarus', isoCode: 'BY', price: '500', conv: '5%'},
        {name : 'Belgium', isoCode: 'BE', price: '1000', conv: '10%'},
        {name : 'Bulgaria', isoCode: 'BG', price: '700', conv: '6%'},
        {name : 'Brazil', isoCode: 'BR', price: '550', conv: '4%'},
        {name : 'United Kingdom', isoCode: 'GB', price: '1000', conv: '10%'},
        {name : 'Hungary', isoCode: 'HU', price: '750', conv: '7%'},
        {name : 'Germany', isoCode: 'DE', price: '1000', conv: '11%'},
        {name : 'Greece', isoCode: 'GR', price: '700', conv: '6%'},
        {name : 'Georgia', isoCode: 'GE', price: '500', conv: '5%'},
        {name : 'Denmark', isoCode: 'DK', price: '1000', conv: '10%'},
        {name : 'India', isoCode: 'IN', price: '450', conv: '3%'},
        {name : 'Ireland', isoCode: 'IE', price: '1000', conv: '10%'},
        {name : 'Iceland', isoCode: 'IS', price: '1000', conv: '10%'},
        {name : 'Spain', isoCode: 'ES', price: '900', conv: '8%'},
        {name : 'Italy', isoCode: 'IT', price: '900', conv: '8%'},
        {name : 'Kazakhstan', isoCode: 'KZ', price: '450', conv: '4%'},
        {name : 'Canada', isoCode: 'CA', price: '1000', conv: '12%'},
        {name : 'Qatar', isoCode: 'QA', price: '900', conv: '8%'},
        {name : 'Kyrgyzstan', isoCode: 'KG', price: '450', conv: '3%'},
        {name : 'China', isoCode: 'CN', price: '900', conv: '10%'},
        {name : 'Colombia', isoCode: 'CO', price: '500', conv: '4%'},
        {name : 'Kuwait', isoCode: 'KW', price: '900', conv: '8%'},
        {name : 'Latvia', isoCode: 'LV', price: '900', conv: '7%'},
        {name : 'Lithuania', isoCode: 'LT', price: '900', conv: '7%'},
        {name : 'Luxembourg', isoCode: 'LU', price: '1000', conv: '10%'},
        {name : 'Malaysia', isoCode: 'MY', price: '900', conv: '8%'},
        {name : 'Malta', isoCode: 'MT', price: '900', conv: '9%'},
        {name : 'Mexico', isoCode: 'MX', price: '500', conv: '3%'},
        {name : 'Moldova', isoCode: 'MD', price: '500', conv: '5%'},
        {name : 'Netherlands', isoCode: 'NL', price: '900', conv: '10%'},
        {name : 'New Zealand', isoCode: 'NZ', price: '1000', conv: '10%'},
        {name : 'Norway', isoCode: 'NO', price: '1000', conv: '10%'},
        {name : 'United Arab Emirates', isoCode: 'AE', price: '900', conv: '5%'},
        {name : 'Oman', isoCode: 'OM', price: '900', conv: '4%'},
        {name : 'Peru', isoCode: 'PE', price: '400', conv: '3%'},
        {name : 'Poland', isoCode: 'PL', price: '800', conv: '8%'},
        {name : 'Portugal', isoCode: 'PT', price: '800', conv: '8%'},
        {name : 'Romania', isoCode: 'RO', price: '700', conv: '6%'},
        {name : 'Saudi Arabia', isoCode: 'SA', price: '800', conv: '4%'},
        {name : 'Serbia', isoCode: 'RS', price: '700', conv: '7%'},
        {name : 'Singapore', isoCode: 'SG', price: '1000', conv: '9%'},
        {name : 'Slovakia', isoCode: 'SK', price: '800', conv: '6%'},
        {name : 'Slovenia', isoCode: 'SI', price: '800', conv: '8%'},
        {name : 'Tajikistan', isoCode: 'TJ', price: '450', conv: '3%'},
        {name : 'Thailand', isoCode: 'TH', price: '900', conv: '4%'},
        {name : 'Turkey', isoCode: 'TR', price: '700', conv: '6%'},
        {name : 'Uzbekistan', isoCode: 'UZ', price: '400', conv: '4%'},
        {name : 'Philippines', isoCode: 'PH', price: '450', conv: '3%'},
        {name : 'Finland', isoCode: 'FI', price: '900', conv: '8%'},
        {name : 'France', isoCode: 'FR', price: '900', conv: '8%'},
        {name : 'Croatia', isoCode: 'HR', price: '700', conv: '7%'},
        {name : 'Czech Republic', isoCode: 'CZ', price: '800', conv: '13%'},
        {name : 'Chile', isoCode: 'CL', price: '500', conv: '4%'},
        {name : 'Switzerland', isoCode: 'CH', price: '1000', conv: '12%'},
        {name : 'Sweden', isoCode: 'SE', price: '1000', conv: '10%'},
        {name : 'Estonia', isoCode: 'EE', price: '900', conv: '9%'},
        {name : 'South Africa', isoCode: 'ZA', price: '500', conv: '3%'},
        {name : 'Japan', isoCode: 'JP', price: '1000', conv: '10%'},
    ]

    return(
        <>
        {
        (isMob) ?   <CountrySelector countrysArray={countryArray}/>
            :
            <MapChart countrysArray={countryArray}/>
        }
        </>
    )

}