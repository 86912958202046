
import './cirkle.css';

export default function Cirkle(){

    return(
        <div className='cirkle_box'>
            <div className='cirkle_box_item cir_1'>
                <div className='cirkle_2222'></div>
            </div>
            <div className='cirkle_box_item cir_2'>
                <div className='cirkle_1111'></div>
            </div>
            <div className='cirkle_box_item cir_3'>
                <div className='cirkle_2222'></div>
            </div>
        </div>


)

}