import './footer.css';

export default function Footer(){


    return(
        <div className='footer'>
            <h1 className='footer_logo'>Belmar</h1>
            <span className='footer_reserv'>
                All rights are reserved (c)
            </span>
        </div>
    )
}